import React, { FC } from 'react';
import { CookiesPopupProps } from '@glise/bricks/CookiesConsent/components/CookiesPopup/CookiesPopup.props';
import styles from './CookiesPopup.module.scss';
import Link from 'next/link';
import IconButton from '@glise/components/common/IconButton/IconButton';
import Button from '@glise/components/common/Button/Button';
import XMarkIcon from '@glise/icons/XMarkIcon';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

const CookiesPopup: FC<CookiesPopupProps> = ({
  setShowCookiesModal,
  setShowPopup,
  handleAcceptAll,
}) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.Overlay} onClick={() => setShowPopup(false)}>
      <div
        className={styles.CookiesConsentPopup}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.Title}>
          <h3>{t('cookiesConsent.title')}</h3>
          <IconButton
            ariaLabel="close"
            onClick={() => setShowPopup(false)}
            size={'sm'}
            style={'transparent-active'}
          >
            <XMarkIcon />
          </IconButton>
        </div>
        <div className={styles.AgreeText}>
          <Trans
            i18nKey="common:cookiesConsent.agreeText"
            components={{ br: <br /> }}
          />
          <Link
            href="/privacy-policy"
            aria-label={t('cookiesConsent.privacyLearnMore')}
          >
            {' '}
            {t('cookiesConsent.privacyLearnMore')}
          </Link>
        </div>
        <div className={styles.ActionButtonsWrapper}>
          <Button
            onClick={() => {
              setShowCookiesModal(true);
              setShowPopup(false);
            }}
            color={'gray'}
            breakpoint={'lg'}
            aria-label={t('cookiesConsent.title')}
          >
            {t('cookiesConsent.title')}
          </Button>
          <Button
            onClick={handleAcceptAll}
            breakpoint={'lg'}
            aria-label={t('cookiesConsent.acceptCookies')}
          >
            {t('cookiesConsent.acceptCookies')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookiesPopup;
